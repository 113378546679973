import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';
import 'intl';

// Polyfill Object.hasOwn
if (!Object.hasOwn) {
	Object.hasOwn = (obj, prop) =>
		Object.prototype.hasOwnProperty.call(obj, prop);
}

// Polyfill Array.prototype.at
if (!Array.prototype.at) {
	Array.prototype.at = function (index) {
		index = Math.trunc(index) || 0;
		if (index < 0) index += this.length;
		if (index < 0 || index >= this.length) return undefined;
		return this[index];
	};
}

// Polyfill globalThis
if (typeof globalThis === "undefined") {
	(function () {
		if (this) {
			this.globalThis = this;
		} else if (typeof window !== "undefined") {
			window.globalThis = window;
		} else if (typeof global !== "undefined") {
			global.globalThis = global;
		} else {
			throw new Error("Unable to locate global object");
		}
	})();
}
