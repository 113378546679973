import "./polyfills";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './redux/store';
import RootComponent from './RootComponent';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<React.StrictMode>
			<RootComponent />
		</React.StrictMode>
	</Provider>
);

// Check for service worker support
if ('serviceWorker' in navigator) {
	window.addEventListener('load', function () {
		navigator.serviceWorker.register('/service-worker.js').then(function (registration) {
			console.log('Service Worker registered with scope:', registration.scope);
		}).catch(function (err) {
			console.log('Service Worker registration failed:', err);
		});
	});
}